<template>
  <div id="app-question-add" class="app-add">
    <loading :loading="loading" text="加载中"></loading>
    <el-table
        :data="records"
        :default-sort = "{prop: 'createdOn', order: 'ascending'}"
        style="width: 100%" v-show="this.loading == false">
      <el-table-column   label="排名" align="center">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="studentId" label="学员ID" align="center"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
      <el-table-column prop="username" label="手机号" align="center" width="150"></el-table-column>
      <el-table-column prop="accuracy"  align="center" sortable width="140">
        <template slot="header" slot-scope="scope">
          <span>正确率({{allAccuracy}}%)</span>
        </template>
        <template slot-scope="scope">{{ scope.row.accuracy }}%</template>
      </el-table-column>
      <el-table-column prop="createdOn" label="完成时间" align="center" width="200" sortable></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">答题记录</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>
<script>
import {request, UrlEnum} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name:"ExamRecord",
  components: {Loading},
  data() {
    return {
      loading:false,
      total:0,
      page:1,
      size:1000,
      paperId:'',
      moduleId:'',
      records: [],
      allAccuracy:0
    }
  },
  methods: {
    handleClick(row){
      console.log(row)
      this.$router.push('../integral/task/question-record?examId='+row.examId);
    },
    pageCurrentChange(val,e,form,jobExpLabel,pageform,searchVal) {
      this.page = val;
      this.initData();
    },
    initData() {
      this.loading=true;
      var url = UrlEnum.PAPER_EXAMS + "/?orderBy=id&orderType=asc&page="+this.page+"&size="+this.size+"&packageId=" + this.paperId+"&moduleId="+this.moduleId;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        this.records = res.data.list;
        if(this.records.length>0){
          for(let i=0;i<this.records.length;i++){
            this.records[i].rank = (i+1)*this.page;
            this.allAccuracy += parseInt(this.records[i].accuracy);
          }
          this.allAccuracy = Math.round(this.allAccuracy / this.records.length);
        }
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  mounted() {
    this.paperId=request("paperId");
    this.moduleId=request("moduleId");
    this.initData();
  }
}
</script>

<style scoped>
@import "../../../static/css/add.css";
</style>
